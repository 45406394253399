exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-index-jsx": () => import("./../../../src/pages/blogs/index.jsx" /* webpackChunkName: "component---src-pages-blogs-index-jsx" */),
  "component---src-pages-blogs-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blogs-my-first-post-mdx": () => import("./../../../src/pages/blogs/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blogs/my-first-post.mdx" /* webpackChunkName: "component---src-pages-blogs-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blogs-my-first-post-mdx" */),
  "component---src-pages-blogs-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blogs-my-journey-into-software-development-part-1-mdx": () => import("./../../../src/pages/blogs/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blogs/my-journey-into-software-development-part-1.mdx" /* webpackChunkName: "component---src-pages-blogs-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blogs-my-journey-into-software-development-part-1-mdx" */),
  "component---src-pages-blogs-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blogs-my-journey-into-software-development-part-2-mdx": () => import("./../../../src/pages/blogs/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blogs/my-journey-into-software-development-part-2.mdx" /* webpackChunkName: "component---src-pages-blogs-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blogs-my-journey-into-software-development-part-2-mdx" */),
  "component---src-pages-blogs-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blogs-post-number-two-mdx": () => import("./../../../src/pages/blogs/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blogs/post-number-two.mdx" /* webpackChunkName: "component---src-pages-blogs-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blogs-post-number-two-mdx" */),
  "component---src-pages-blogs-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blogs-third-example-blog-mdx": () => import("./../../../src/pages/blogs/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blogs/third-example-blog.mdx" /* webpackChunkName: "component---src-pages-blogs-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blogs-third-example-blog-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

